const Hasher = function(source, salt){
    let Hasher = new source(salt)

    this.encode = function(input){
        let output
        if(input){
            output = Hasher.encode(input)
        }
        // console.log('encode',{input, output})
        return output
    }

    this.decode = function(input){
        let output
        if(input){
            output = Hasher.decode(input)
        }
        // console.log('decode',{input, output})
        return output && output.length>0 ? output[0] : output
    }
}

module.exports = {
    Hasher
}