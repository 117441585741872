<template>
<div style="max-width: 1400px; margin: 0 auto;">

    <div v-if="debug">
        event_id: {{Hasher.decode(event_id)}}<br>
        <v-btn :disabled="ui.step<=0" @click="ui.step--">
            <v-icon>
                mdi-chevron-left
            </v-icon>
        </v-btn>

        <v-btn :disabled="ui.step>=config.steps.length" @click="ui.step++">
            <v-icon>
                mdi-chevron-right
            </v-icon>
        </v-btn>
    </div>


    <v-expansion-panels v-if="ready" v-model="ui.step" :mandatory="true">
        <v-expansion-panel v-for="(step, stepIndex) in config.steps" :key="'step_'+stepIndex" :class="[ui.stepState[stepIndex+1] ? 'complete' : '',`step_${stepIndex+1}`]">
            <v-expansion-panel-header :disabled="!debug && !ui.stepState[(stepIndex+1)]">
                <div class="header-content">
                    <v-icon v-if="config.steps[stepIndex].icon.type=='mdi'" large>
                        {{config.steps[stepIndex].icon.ref}}
                    </v-icon>

                    <v-avatar left v-if="config.steps[stepIndex].icon.type=='png'">
                    <v-img :src="images[config.steps[stepIndex].icon.ref]"></v-img>
                    </v-avatar>

                    <str :index="config.steps[stepIndex].str"/>

                    <v-icon v-if="ui.stepState[stepIndex+1]">
                        mdi-check-bold
                    </v-icon>
                </div>

                <template v-slot:actions>
                    <v-icon large :class="stepIndex==ui.step ? 'direction-down' : 'direction-right'">
                        mdi-menu-down
                    </v-icon>
                </template>

            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
                <template v-if="stepIndex==0">
                    <div v-if="debug && evaluations.length>0">
                        <v-toolbar color="light-blue" dark>
                            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                            <v-toolbar-title>Saved Evaluations</v-toolbar-title>
                            <v-spacer/>
                        </v-toolbar>
                        <v-list dense>
                            <template v-for="evaluation in evaluations">
                                <v-list-item v-if="evaluation.event_id==Hasher.decode(event_id)" :key="'record_id_'+evaluation.id">
                                    <v-list-item-avatar>
                                        {{evaluation.id}}
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        Record Submitted: {{evaluation.timestamp}}
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn @click="deleteEvaluation(evaluation)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>

                                </v-list-item>

                            </template>
                        </v-list>
                    </div>
                    <Survey v-if="!ui.stepState[1]" :questionSetID="Hasher.decode(questionSetID)" :requestDebug="debug" :questionSet="questionSet" @submit="submitEval"/>
                    <div v-else style="margin: 50px auto; text-align: center;">
                        <h3>
                            <str index="post_event.messages.received"/>
                        </h3>
                        <v-btn color="green" dark @click="ui.step++">
                            <str index="buttons.continue"/>
                        </v-btn>
                    </div>
                </template>
                <SampleRequest v-if="stepIndex==1" :Hasher="Hasher" :event_id="Hasher.decode(event_id)" @complete="completeSampleRequest" @resetStep="resetStepSampleRequest" @continue="ui.step++"/>
                <Certificate  v-if="stepIndex==2" :Hasher="Hasher" :event_id="Hasher.decode(event_id)" :speaker="speaker" :eventData="eventData" @complete="completeCertificate" @resetStep="resetStepCertificate" @continue="ui.step++"/>
                <div v-if="stepIndex==3" style="margin: 50px auto;">
                    <!-- CONDITIONAL SHOW - consent NOT answered -->
                    <div v-if="consentFormAnswered==false && isVIP==false">
                        <!-- TITLE -->
                        <h3 style='text-align:center;'><str index="post_event.steps.thank_you.message.title"/></h3>
                        <!-- BLURB 1 -->
                        <div style="margin: 25px;">
                            <str index="post_event.steps.thank_you.message.consent_part1"/>
                        </div>
                        <!-- RADIOS -->
                        <div style="margin: 25px; display:flex;">
                            <input type='radio' id="consent_yes" name="consent" @click="give_consentFormAnswer" style='display:block; width:24px;'/>
                            <label for="consent_yes" style='margin-left:8px; font-weight:bold;'>
                                <str index="post_event.steps.thank_you.consent_yes"/>
                            </label>
                        </div>
                        <div style="margin: 25px; display:flex;">
                            <input type='radio' id="consent_no" name="consent" @click="give_consentFormAnswer" style='display:block; width:24px;'/>
                            <label for="consent_no" style='margin-left:8px; font-weight:bold;'>
                                <str index="post_event.steps.thank_you.consent_no"/>
                            </label>
                        </div>
                        <!-- BLURB 2 -->
                        <div style="margin: 25px;">
                            <str index="post_event.steps.thank_you.message.consent_part2"/>
                        </div>
                    </div>
                    <!-- CONDITIONAL SHOW - consent answered -->
                    <div v-if="consentFormAnswered==true || isVIP==true">
                        <!-- BLURB 3 -->
                        <h3 style='font-weight:bold; font-size:24px; text-align:center; margin-bottom:24px;'>
                            <str index="post_event.steps.thank_you.message.thank_you"/>
                        </h3>
                        <!-- BACK-TO-HOME BUTTON -->
                        <v-btn color="teal" dark @click="$router.push({name:'Home'})" style="display:block; margin:0 auto;">
                            <str index="post_event.buttons.home"/>
                        </v-btn>
                    </div>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

    <!-- MODAL - Confirm leave page -->
    <v-dialog v-model="leaveMenu" width="500" persistent>
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                <str index="buttons.confirm"/>?
            </v-card-title>
            <br>
            <v-card-text>
                <str index="evaluation.view.confirm_leave"/>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="confirmLeave=false" color="primary" text>
                    <str index="buttons.cancel"/>
                </v-btn>
                <v-btn @click="confirmLeave=true" color="primary" text>
                    <str index="buttons.confirm"/>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- MODAL - Privacy Policy -->
    <v-dialog v-model="popup" :width="this.$store.getters.width < 768 ? '100%' : '50%'">
        <v-card style='padding:32px 0 16px;'>
            <v-card-title style='word-break:break-word;' class='terms_privacy_headers'>
                <str v-if="content=='privacy'" index="footer.privacy_policy.title"/>
            </v-card-title>
            <v-card-text>
                <str v-if="content=='privacy'" index="footer.privacy_policy.content"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="popup=false">
                    <str index="buttons.close"/>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import bottles from '@/assets/images/evaluation/bottles.png'
import Hashids from 'hashids'
let {Hasher} = require('@/plugins/hasher.js')

import cache from '@/plugins/cache.js'
import Survey from '@/components/Questions/QuestionSet'
import SampleRequest from '@/components/SampleRequest/SampleRequest'
import Certificate from '@/components/Certificate/Certificate'

export default {
    name: "Evaluation",
    components: {
        Survey,
        SampleRequest,
        Certificate
    },
    created: function(){
        // let key = this.questionSetID
        // let storage = window.localStorage;
        // let cache = storage.getItem('survey_editor_cache');
        // if(cache){
        //     cache = JSON.parse(cache);
        //     if(cache[key]){
        //         this.questionSet = cache[key]
        //     }
        // }

        // this.Hasher = new Hashids('Temporary Sald')
        this.Hasher = new Hasher(Hashids, this.$store.getters.hasherSalt)
        this.init()
    },
    data: function(){
        return {
            add_triggerPrivacyPolicy_eventListener: null,
            auto_selected: false,
            ready: false,
            restricted: false,
            ui: {
                step: 0,
                stepState: {
                    1: false,
                    2: false,
                    3: false,
                    4: false
                }
            },
            state: 'confirm_access',
            config: {
                states: ['confirm_access','allowed','not_allowed','loading','ready','completed'],
                steps: [
                    {icon: {type: 'mdi', ref: 'mdi-format-list-bulleted'}, str: 'post_event.steps.evaluation.name'},
                    {icon: {type: 'png', ref: 'bottles'}, str: 'post_event.steps.samples_request.name'},
                    {icon: {type: 'mdi', ref: 'mdi-certificate'}, str: 'post_event.steps.certificate.name'},
                    {icon: {type: 'mdi', ref: 'mdi-heart-circle'}, str: 'post_event.steps.thank_you.name'}

                ]
            },
            // questionSetID: 'evaluation',
            questionSet: null,
            form: {
                survey: {},
                sampleRequest: null,
                certificate: null
            },
            enrollment_records: [],
            evaluations: [],
            Hasher: null,
            timer: null,
            eventData: null,
            speaker: null,
            leaveMenu: false,
            confirmLeave: null,
            checkingLeave: null,
            consentFormAnswered: false,
            popup: false,
            content: null,
            isVIP: null
        }
    },
    methods: {
        init: async function(){
            this.add_clickListener_toPrivacyPolicyLabel()
            await this.pass_VIPStatus_toIsVIP()

            await this.getQuestionSet()
            await this.getEnrolledEvents()
            await this.getEvaluations()
            await this.getEvent()


            if( !this.isEligible ){
                alert('Not eligible for this evaluation')
                this.restricted = true
            }else{
                if(this.enrollmentRecord.attended!='Y'){
                    console.error('Must have attended')
                    this.restricted = true
                }
            }
            if(this.restricted){
                this.$router.push({name:'Restricted'})
            }
            this.ready = true
        },
        add_clickListener_toPrivacyPolicyLabel: function(){
            let self = this;
            self.add_triggerPrivacyPolicy_eventListener = setInterval(function(){
                let element = document.querySelector('.triggerPrivacyPolicy');
                if( element != undefined){
                    element.addEventListener('click', function(){
                        self.popup = true;
                        self.content = 'privacy'
                    });
                    clearInterval(self.add_triggerPrivacyPolicy_eventListener);
                };
            },1000);
        },
        getQuestionSet: async function(){
            let self = this
            let getData = async function(){
                let response
                try{
                    console.log('getQuestionSet',`/api/questionSet/get/${self.questionSetID}`)
                    response = await self.sendRequest('get',`/api/questionSet/get/${self.questionSetID}`)
                }catch(err){
                    console.error('getQuestionSet error', err)
                }
                return response.status==200 ? response.data : response
            }

            self.questionSet = await getData()
        },
        getEvent: async function(){
            let eventData = await this.sendRequest('get',`/api/event/search/${this.event_id}`)
            let speakers = await this.sendRequest('get','/api/getSpeakers')
            let speaker
            for(let i=0; i<speakers.data.length; i++){
                let record = speakers.data[i]
                if(record.id == eventData.data.speakers[0]){
                    speaker = record
                }
            }
            this.eventData = eventData.data
            this.speaker = speaker
        },
        getEnrolledEvents: async function(){
            let self = this
            function getEnrolled(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/event/enrolled').then((response)=>{
                        resolve(response.data)
                    },(error)=>{
                        reject(error)
                    })
                })
            }

            let response = await getEnrolled()
            self.enrollment_records = response
        },
        getEvaluations: async function(){
            let self = this
            let getData = async function(){
                let answers = await self.sendRequest('get',`/api/questionSet/answers/${self.questionSetID}/${self.event_id}`)
                return answers.status==200 ? answers.data : answers
            }

            self.evaluations = []
            let results  = await getData()
            self.evaluations = results
            let event_id = self.Hasher.decode(self.event_id)
            let completed = 0
            for(let i=0; i<results.length; i++){
                let record = results[i]
                let received = record.event_id==event_id
                if(received){
                    completed++
                }
            }
            this.ui.stepState[1] = completed>0
        },
        deleteEvaluation: async function(evaluation){
            let record_id = evaluation.id
            let user_id = evaluation.userId
            let event_id = evaluation.event_id
            let response = await this.sendRequest('delete','/api/questionSet/admin/answers/delete',{
                record_id,
                user_id,
                event_id
            })
            await this.getEvaluations()

        },
        submitEval: async function(data){
            let self = this
            async function save(data){
                let response = await self.sendRequest('post',`/api/questionSet/answers/${self.Hasher.encode(self.questionSet.id)}/${self.Hasher.encode(self.questionSet.version)}`, data)
                return response.status==200 ? response.data : response
            }

            this.form.survey = data
            let evaluation = {
                answers: data,
                event_id: this.Hasher.decode(this.event_id)
            }
            let response = await save(evaluation)
            await self.getEvaluations()

            let notifications = this.$store.getters.notifications
            for(let i=0; i<notifications.length; i++){
                let notification = notifications[i]
                if(notification.type=='missingEvaluation' && notification.id==this.event_id){
                    notifications.splice(i,1)
                }
            }
            self.completeEvaluation(true)
        },
        completeEvaluation: function(data){
            if(this.debug || data===true){
                this.ui.stepState[1] = data
            }
        },
        completeSampleRequest: function(data){
            if(this.debug || data===true){
                this.ui.stepState[2] = data
            }
        },
        completeCertificate: function(data){
            if(this.debug || data===true){
                this.ui.stepState[3] = data
            }
        },
        saveStepState: async function(){
            let self = this


            let response = await self.sendRequest('patch', `/api/postEvent/stepStates`,{
                record_id: self.enrollmentRecord.id,
                event_id: self.Hasher.decode(self.event_id),
                step_states: self.ui.stepState
            })
            // console.log('saveStepState', response)
        },
        resetStepSampleRequest: function(){
            this.ui.stepState[2] = false
        },
        resetStepCertificate: function(){
            this.ui.stepState[3] = false
        },
        answerUserLeave:function(){
            return new Promise((resolve, reject)=>{
                let self = this;
                self.checkingLeave = setInterval(function(){
                    if( self.confirmLeave == true){
                        self.confirmLeave = null;
                        resolve(true);
                        clearInterval(self.checkingLeave);
                    }
                    else
                    if( self.confirmLeave == false){
                        self.confirmLeave = null;
                        resolve(false);
                        clearInterval(self.checkingLeave);
                    }
                },300);
            });
        },
        give_consentFormAnswer: function(){
            this.consentFormAnswered = true;
            let id = event.srcElement.id;
            if( id == 'consent_yes'){
                let user_id = this.$store.getters.user.attributes.sub;
                let email = this.$store.getters.user.attributes.email;
                let hubspot_id = this.$store.getters.user.attributes['custom:hubspot_id'];
                this.sendRequest('post','/api/log/vip_click',{
                    user_id,
                    email,
                    hubspot_id
                });
                this.isVIP = true;
            };
        },
        pass_VIPStatus_toIsVIP: async function(){
            return new Promise((resolve, reject)=>{
                let self = this;
                let url = '/api/dashboardMetrics';
                let sendObj = {
                    getVIP: true,
                    userId: this.$store.getters.user.attributes.sub
                };
                self.sendRequest('POST', url, sendObj).then((response)=>{
                    this.isVIP = response.data;
                    resolve();
                });
            });
        }
    },
    async beforeRouteLeave (to, from , next) {
        let completedSteps = 0
        for(let step in this.ui.stepState){
            if(this.ui.stepState[step]){
                completedSteps++
            }
        }
        if(this.ui.stepState[3]===true || !this.$store.getters.signedIn || !this.isEligible){
            next()
        }else{
            this.leaveMenu = true;
            let answer = await this.answerUserLeave();
            if (answer ) {
                next()
            } else {
                next(false)
            }
            this.leaveMenu = false;
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        event_id: function(){
            // return this.Hasher.decode(this.$route.params.event_id)
            return this.$route.params.event_id
        },
        questionSetID: function(){
            // return this.Hasher.decode(this.$route.params.question_set_id)
            return this.$route.params.question_set_id
        },
        language: function(){
            return this.$store.getters.language
        },
        images: function(){
            return {
                bottles
            }
        },
        stepState: function(){
            return this.ui.stepState
        },
        enrollmentRecord: function(){
            if(this.Hasher){
                let event_id = this.Hasher.decode(this.event_id)
                let records = this.enrollment_records
                for(let i=0; i<records.length; i++){
                    let record = records[i]
                    if(record.event_id==event_id){
                        return record
                    }
                }
            }
            return null
        },
        completedOtherPostEvent: function(){
            function isAllTrue(object){
                if(typeof object=='object' && object!=null){
                    for(let i in object){
                        if(!object[i]){
                            return false
                        }
                    }
                    return true
                }else{
                    return false
                }
            }
            let output = false
            let records = this.enrollment_records
            let event_id = this.Hasher.decode(this.event_id)
            for(let i=0; i<records.length; i++){
                let record = records[i]
                if(record.event_id!=event_id && isAllTrue(record.step_states) && record.attended=='Y' && !this.debug){
                    output = true
                }
            }

            return output
        },
        isEligible: function(){
            return (this.enrollmentRecord && this.enrollmentRecord.enrolled=='Y')
        }
    },
    watch: {
        language: function(after, before){
            this.add_clickListener_toPrivacyPolicyLabel()
        },
        enrollmentRecord: function(){
            for(let key in this.enrollmentRecord.step_states){
                this.ui.stepState[key] = this.enrollmentRecord.step_states[key] === true
            }
        },
        stepState: {
            deep: true,
            handler: function(){
                let stepState = this.stepState
                let targetIndex
                let completed = 0
                for(let step in stepState){
                    let state = stepState[step]
                    if(!state){
                        let index = parseInt(step, 10)
                        if(targetIndex===undefined){targetIndex = index-1}
                    }else{
                        completed++
                    }
                }
                // this.ui.stepState[this.config.steps.length] = completed>=this.config.steps.length-1
                if(!this.auto_selected){
                    this.auto_selected = true
                    this.ui.step = targetIndex!=undefined ? targetIndex : this.config.steps.length-1
                    console.log('step setter: ',{
                        targetIndex,
                        completed
                    })
                }

                if(stepState[this.config.steps.length-1]){
                    this.ui.stepState[this.config.steps.length] = true
                }else{
                    this.ui.stepState[this.config.steps.length] = false
                }

                let self = this
                if(self.ready){
                    clearTimeout(self.timer)
                    self.timer = setTimeout(function(){
                        self.saveStepState()
                    },800)
                }
            }
        }

    }

}
</script>

<style scoped lang="scss">
.direction-right{
    transform: rotate(-90deg) !important;
}
.direction-down{
    transform: rotate(0deg) !important;
}

.v-expansion-panel-header{
    font-size: 14pt;
    text-transform: capitalize;

}
.complete .v-expansion-panel-header{
    background-color: #E0EFCF !important;
}

.complete .v-icon{
    color: green !important;
}

.step_2 button .header-content{
    position: relative;
    left: -12px;
}
.step_2 button .header-content .v-avatar{
    left: 5px;
}
</style>
