<template>
<div>
    <div v-if="debug">
        <div v-if="debug && requests.length>0">
            <v-toolbar color="light-blue" dark>
                <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                <v-toolbar-title>Records</v-toolbar-title>
                <v-spacer/>
            </v-toolbar>
            <v-list dense>
                <v-list-item v-for="record in requests" :key="'record_id_'+record.id">
                    <v-list-item-avatar>
                        {{record.id}}
                    </v-list-item-avatar>
                    <v-list-item-content>
                        Record Submitted: {{record.timestamp}}
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn @click="deleteRecord(record)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>

                </v-list-item>
            </v-list>
        </div>
    </div>


    <div class="disclaimer">
        <h3>
            <str index="post_event.steps.samples_request.disclaimer"/>
        </h3>
        <p>
            <str index="post_event.steps.samples_request.disclaimer_blurb"/>
        </p>
    </div>
    <img :src="images.samples[language] ? images.samples[language] : images.samples.en" style="width:100%;"/>
    <!-- <template v-for="(item, itemIndex) in products">
        <v-list-item :key="'product_'+item.itemNumber">
            <v-list-item-content>
                <div class="item-content">
                    <img :src="item.image"/>
                    <div style="align-self: center;">
                        <h3>{{item.title}}</h3>
                        {{item.description}}
                        <br>
                        Item Number: {{item.itemNumber}}
                    </div>
                </div>            
            </v-list-item-content>

            <v-list-item-action style="flex-direction:row;align-self:center;">
                <v-text-field type="number" v-model="form[item.itemNumber]" dense outlined label="Units" style="width:80px;display: inline;" :rules="rules.positveNumber"/>
                <div style="position: relative; top: -20px;">
                    <v-btn class="elevation-0" @click="remove(item.itemNumber)" style="margin: 5px;">-</v-btn>
                    <v-btn class="elevation-0" @click="add(item.itemNumber)" style="margin: 5px;">+</v-btn>
                </div>
            </v-list-item-action>
        </v-list-item>
        <v-divider :key="itemIndex"></v-divider>
    </template> -->
    <div class="request">
        <v-checkbox dark v-model="form.request">
            <template v-slot:label>
                <span style="color: white; font-weight: bold;">
                    <str index="post_event.buttons.request_sample"/>
                </span>
            </template>
        </v-checkbox>
    </div>


    <v-form ref="requestForm" v-model="formValidation.request" v-if="form.request && requests.length==0" style="width: 600px; margin: 0 auto; margin-top: 50px;">
        <h3><str index="sample_request.text.shipping_info"/></h3><br>

        <template v-for="(field, field_index) in fields">

            <v-text-field v-if="field.type=='textfield'" :key="`field_${field_index}`" outlined v-model="form.contact[field.target]" 
                :required="field.required"
                :rules="field.rules"
            >
                <template v-slot:label>
                    <str :index="field.label"/>
                </template>
            </v-text-field>

            <v-autocomplete v-if="field.type=='autocomplete'" :key="`field_${field_index}`" v-model="form.contact[field.target]" 
                :items="field.items" 
                :required="field.required"
                :rules="field.rules"
                background-color="white"
                autocomplete="disable_autocomplete"
                dense
                outlined
            >
                <template v-slot:label>
                    <str :index="field.label" />
                </template>                    
            </v-autocomplete>

            <v-select v-if="field.type=='select'" :key="`field_${field_index}`" v-model="form.contact[field.target]"
                :items="field.items" 
                :required="field.required"
                :rules="field.rules"
                background-color="white"
                dense
                outlined
            >
                <template v-slot:label>
                    <str :index="field.label" />
                </template>    

            </v-select>

            <v-checkbox v-if="field.type=='casl'" :true-value="true" :false-value="false" :key="`field_${field_index}`" v-model="form.contact[field.target]"
                :items="field.items" 
                background-color="white"
                dense
                outlined
                style="position: relative; top: -20px;"
            >
                <template v-slot:label>
                    <str :index="field.label" style="position: relative; top: 20px;"/>
                </template>    

            </v-checkbox>

        </template>
        <v-btn @click="submit" :disabled="!formValidation.request">
            <str index="buttons.submit"/>
        </v-btn>
    </v-form>
    <div v-else-if="form.request && requests.length>0" style="margin: 50px auto; text-align: center;">
        <h3>
            <str index="post_event.steps.samples_request.success"/>
        </h3>
    </div>
    <div :class="['d-flex', $vuetify.breakpoint.xsOnly ? 'justify-center':'justify-end']">
        <v-btn class="green" :disabled="(form.request && requests.length==0)" @click="$emit('continue');$emit('complete', true)">
            <str index="buttons.continue"/>
        </v-btn>
    </div>
</div>
</template>

<script>
/*
TODO: request for "TRIAL SIZES Samples - based on availability"
- samples are limited
- options are limited to 2 SKUs > 
*/
import validations from '@/plugins/validations.js'
import samples from './assets/samples.png'

export default {
    props: {
        event_id: {
            type: Number,
            required: true
        },
        Hasher: {
            type: Object,
            required: true
        }
    },
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        let attributes = JSON.stringify(this.$store.getters.user.attributes)
        attributes = JSON.parse(attributes)
        this.form.contact.clinic = attributes['custom:clinic']
        this.form.contact.country = attributes['custom:country']
        this.form.contact.prov_state = attributes['custom:prov_state']
        this.form.contact.city = attributes['custom:city']
        this.form.contact.address = attributes['custom:address']
        this.form.contact.postal_zip = attributes['custom:postal_zip']
        this.init()
    },
    data: function(){
        return {
            form: {
                request: false,
                contact: {
                    clinic: null,
                    country: null,
                    prov_state: null,
                    city: null,
                    address: null,
                    postal_zip: null,
                }
            },
            formValidation: {
                request: false
            },
            products: null,
            html: null,
            validations: null,
            rules: null,
            requests: [],
            db: {
                countries: [],
                states: [],
                cities: []
            }
        }
    },
    methods: {
        init: async function(){
            let self = this
            await this.lookup()
            this.getCountries()
            this.getStates()
            this.getCities()
        },
        getCountries: async function(){
            async function getCountries(){
                let response
                try {
                    response = await self.sendRequest('get','/api/csc/getCountries')    
                } catch (error) {
                    console.error('getCountries error:', error)
                }
                return response.status==200 ? response.data : []
            }

            let self = this

            let getCountriesResponse = await getCountries()
            self.db.countries = getCountriesResponse
        },
        getStates: async function(){
            async function getData(country){
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getStates/${country}`)
                } catch (error) {
                    console.error('getStates error', error)                    
                }
                return response.status==200 ? response.data : []
            }

            let self = this
            let country = self.form.contact.country
            if(typeof country=='string' && country.length>0){
                self.db.states = await getData(country)
            }else{
                console.error('getStates error: missing required data', country)
            }
        },
        getCities: async function(){
            async function getData(country, prov_state){
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getCities/${country}/${prov_state}`)
                } catch (error) {
                    console.error('getCities error', error)                    
                }
                return response.status==200 ? response.data : []
            }

            let self = this
            let country = self.form.contact.country
            let prov_state = self.form.contact.prov_state
            if(((typeof country=='string' && country.length>0)) && (typeof prov_state=='string' && prov_state.length>0)){
                self.db.cities = await getData(country, prov_state)
            }else{
                console.error('getCities error: missing required data', {country, prov_state})
            }
        },
        deleteRecord: async function(record){
            let record_id = record.id
            let user_id = record.user_id
            let event_id = record.event_id
            let response = await this.sendRequest('delete','/api/samples/admin/request/delete',{
                record_id,
                user_id,
                event_id
            })
            await this.init()
            this.$emit('resetStep')

        },
        lookup: async function(){
            this.requests = []
            let response = await this.sendRequest('get', `/api/samples/lookup/${this.Hasher.encode(this.event_id)}`)
            if(response.status==200 && response.data){
                this.requests = response.data
                this.form.request = response.data.length>0
            }
        },
        positveNumber: function(v){
            let value = parseInt(v,10)
            return !isNaN(value) && value>=0
        },
        submit: async function(){
            let data = {
                contact: this.form.contact,
                event_id: this.event_id
            }
            let response = await this.sendRequest('post','/api/samples/request', data)
            let self = this
            self.init()
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        images: function(){
            return {
                samples: {
                    en: samples
                }
            }
        },
        language: function(){
            return this.$store.getters.language
        },
        fields: function(){
            let output = [
                {
                    target: 'clinic',
                    label: 'forms.clinic',
                    required: true,
                    rules: this.rules.required,
                    type: 'textfield'
                },
                {
                    target: 'country',
                    label: 'forms.country',
                    required: true,
                    rules: this.rules.required,
                    type: 'autocomplete',
                    items: this.countries
                },
                {
                    target: 'prov_state',
                    label: 'forms.province',
                    required: true,
                    rules: this.rules.required,
                    type: 'autocomplete',
                    items: this.states
                },
                {
                    target: 'city',
                    label: 'forms.city',
                    required: true,
                    rules: this.rules.required,
                    type: 'autocomplete',
                    items: this.cities
                },
                {
                    target: 'address',
                    label: 'forms.address',
                    required: true,
                    rules: this.rules.required,
                    type: 'textfield'
                },
                {
                    target: 'postal_zip',
                    label: 'forms.postal_code',
                    required: true,
                    rules: this.rules.required,
                    type: 'textfield'
                }
            ]

            return output
        },
        csc: function(){
            return {
                country: this.form.contact.country,
                prov_state: this.form.contact.prov_state,
                city: this.form.contact.city
            }
        },
        countries: function(){
            let list = []
            let countries = this.db.countries
            for(let i=0; i<countries.length; i++){
                let country = countries[i]
                list.push({
                    text: country.name,
                    value: country.code
                })
            }
            return list
        },
        states: function(){
            let list = []
            let states = this.db.states
            for(let i=0; i<states.length; i++){
                let state = states[i]
                list.push({
                    text: state.name,
                    value: state.code
                })
            }
            return list
        },
        cities: function(){
            let list = []
            let cities = this.db.cities
            for(let i=0; i<cities.length; i++){
                let city = cities[i]
                list.push({
                    text: city.name,
                    value: city.name
                })
            }

            return list
        }
    },
    watch: {
        csc: {
            deep: true,
            handler: function(after, before){
                if(after.country!=before.country){
                    this.getStates()
                }
                if(after.prov_state!=before.prov_state){
                    this.getCities()
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.item-content{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.green{
    margin: 25px;
    color: white;
    background-color: $secondary !important;
}

.disclaimer{
    padding: 15px;
    margin: 25px;
    background-color: $secondary-light;
}

h3{
    color: $secondary;
}

.request{
    margin: 0 auto;
    margin-top: 25px;
    width: 100%;
    max-width: 400px;
    padding: 5px 15px 0px 15px;
    background-color: $secondary;
    display: flex;
    justify-content: center;
}
</style>